// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface ICreateSavedSearchWebPublicRequestSchema {
  /** Указанный еmail **/
  email: string;
  /** Частота рассылки уведомлений **/
  frequency: EFrequency;
  /** Набор фильтров словарем **/
  jsonQuery: object;
  /** Где пользователь создал СП: карте, выдача **/
  source?: ESource;
  /** Подписать ли на новости **/
  subscribeNews: boolean;
  /** Название сохраненного поиска **/
  title: string;
}

export enum EFrequency {
  /** Получать мнгновенно **/
  'Asap' = 'asap',
  /** Получать каждый день **/
  'Daily' = 'daily',
  /** Получать каждый час **/
  'Hourly' = 'hourly',
  /** Получать еженедельно **/
  'Weekly' = 'weekly',
}
export enum ESource {
  'BookmarksScreen' = 'bookmarksScreen',
  /** deprecated (для поддержки старых клиентов) **/
  'Card' = 'card',
  'FavoriteHousesScreen' = 'favoriteHousesScreen',
  'FiltersScreen' = 'filtersScreen',
  /** deprecated (для поддержки старых клиентов) **/
  'Listing' = 'listing',
  /** deprecated (для поддержки старых клиентов) **/
  'Map' = 'map',
  'NearbySearchScreen' = 'nearbySearchScreen',
  'OfferScreen' = 'offerScreen',
  'PersonalFeedScreen' = 'personalFeedScreen',
  'ProfilingScreen' = 'profilingScreen',
  'RecommendationsScreen' = 'recommendationsScreen',
  'SaveSearchListScreen' = 'saveSearchListScreen',
  'SearchHistoryScreen' = 'searchHistoryScreen',
  'SearchResultsList' = 'searchResultsList',
  'SearchResultsMap' = 'searchResultsMap',
  'Telegram' = 'telegram',
}
