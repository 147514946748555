// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { TGetMetaRequest, TGetMetaModel, IMappers, TGetMetaResponse } from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IV2GetMetaResponseSchema } from '../../entities/get_meta/V2GetMetaResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetMetaModel(parameters: TGetMetaRequest): TGetMetaModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers-index',
    pathApi: '/v2/get-meta/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IGetMetaOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetMetaRequest;
}

export async function fetchGetMeta<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetMetaOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetMetaResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetMetaModel(parameters),
    config,
  )) as FetchResponse<TGetMetaResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IV2GetMetaResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetMetaResponse>;
}
