// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetNewbuildingLayoutsCountRequest,
  TGetNewbuildingLayoutsCountModel,
  IMappers,
  TGetNewbuildingLayoutsCountResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetNewbuildingLayoutsCountResponseSchema } from '../../entities/get_newbuilding_layouts_count/GetNewbuildingLayoutsCountResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetNewbuildingLayoutsCountModel(
  parameters: TGetNewbuildingLayoutsCountRequest,
): TGetNewbuildingLayoutsCountModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers-index',
    pathApi: '/v1/get-newbuilding-layouts-count/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetNewbuildingLayoutsCountRequest;
}

export async function fetchGetNewbuildingLayoutsCount<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetNewbuildingLayoutsCountResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetNewbuildingLayoutsCountModel(parameters),
    config,
  )) as FetchResponse<TGetNewbuildingLayoutsCountResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingLayoutsCountResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetNewbuildingLayoutsCountResponse>;
}
