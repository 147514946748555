// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IHideOfferRequestSchema {
  /**  Тип сделки  **/
  dealType: EDealType;
  /**  ID дома, указанного в объявлении  **/
  houseId?: number;
  /**  Realty ID объявления  **/
  offerId: number;
  /**  Тип объявления  **/
  offerType: EOfferType;
  /**  Realty ID пользователя, опубликовавшего объявление  **/
  publishedUserId: number;
}

export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EOfferType {
  /** Коммерческая недвижимость **/
  'Commercial' = 'commercial',
  /** Квартира **/
  'Flat' = 'flat',
  /** Новостройки **/
  'Newobject' = 'newobject',
  /** Загородная недвижимость **/
  'Suburban' = 'suburban',
}
