// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  IRegionalDistrictsGetByLocationIdRequest,
  TRegionalDistrictsGetByLocationIdModel,
  IMappers,
  TRegionalDistrictsGetByLocationIdResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IPublicGetRegionalDistrictsByLocationIdResponseModel } from '../../../entities/RegionalDistricts/PublicGetRegionalDistrictsByLocationIdResponseModel';
import type { IErrorResponseSchema } from '../../../entities/swagger-generator/ErrorResponseSchema';

export function createRegionalDistrictsGetByLocationIdModel(
  parameters: IRegionalDistrictsGetByLocationIdRequest,
): TRegionalDistrictsGetByLocationIdModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'GET',
    microserviceName: 'geo',
    pathApi: '/v1/regional-districts/get-by-location-id/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      parameters,
    },
  };
}

export interface IRegionalDistrictsGetByLocationIdOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IRegionalDistrictsGetByLocationIdRequest;
}

export async function fetchRegionalDistrictsGetByLocationId<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IRegionalDistrictsGetByLocationIdOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TRegionalDistrictsGetByLocationIdResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createRegionalDistrictsGetByLocationIdModel(parameters),
    config,
  )) as FetchResponse<TRegionalDistrictsGetByLocationIdResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPublicGetRegionalDistrictsByLocationIdResponseModel, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TRegionalDistrictsGetByLocationIdResponse>;
}
