import { equals } from 'ramda';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SuggestionsLayout } from './components/SuggestionsLayout';
import { useSuggestionsOffersRenderer } from './useSuggestionsOffersRenderer';
import { useSuggestionsTracking } from './useSuggestionsTracking';
import { getOffers, getSuggestionIds } from 'shared/api/offers';
import { type IAppState } from 'shared/common/state';
import { type IOffer } from 'shared/offer';
import {
  type ICoordinatesOffers,
  requestSuggestionsDistances,
  setQsToUris,
  setSuggestionDistancesSeoText,
} from 'shared/serp/state/suggestions';
import { fetchGeoTagsSafe } from 'shared/services/fetchGeoTags';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { getSuggestionDistancesSeoText } from 'shared/utils/seo_text';

export function SuggestionsContainer() {
  const ctx = useApplicationContext();

  const dispatch = useDispatch();

  const offers = useSelector((state: IAppState) => state.results.offers);
  const suggestions = useSelector((state: IAppState) => state.results.suggestions);
  const jsonQuery = useSelector((state: IAppState) => state.results.jsonQuery);
  const subdomain = useSelector((state: IAppState) => state.currentSubdomain);
  const queryString = useSelector((state: IAppState) => state.results.queryString);
  const directions = useSelector((state: IAppState) => state.filters.directionsModal);
  const fullUrl = useSelector((state: IAppState) => state.results.fullUrl);

  const suggestionsRef = useRef<IOffer[] | null>(null);
  const mountedRef = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [nextStepSuggestions, setNextStepSuggestions] = useState<number[]>([]);
  const [additionalSuggestions, setAdditionalSuggestions] = useState<IOffer[][]>([]);
  const [modelVersion, setModelVersion] = useState<string>('0');

  const renderCards = useSuggestionsOffersRenderer();
  const handleTrackSuggestions = useSuggestionsTracking();

  const isSeoUrl = useMemo(() => {
    const { pathname } = new URL(fullUrl);

    return !pathname.startsWith('/cat.php');
  }, [fullUrl]);

  const visibleSuggestionsNodes = useMemo<ReactNode[]>(() => {
    return [
      ...renderCards({
        pageNumber: 1,
        offers: suggestions ?? [],
      }),
      ...additionalSuggestions.map((additionalSuggestions, additionalSuggestionsPage) => {
        return renderCards({
          offset: suggestions?.length ?? 0,
          pageNumber: additionalSuggestionsPage + 1,
          offers: additionalSuggestions,
          suggestions: [...(suggestions || []), ...additionalSuggestions],
        });
      }),
    ];
  }, [additionalSuggestions, renderCards, suggestions]);

  const allVisibleOffersIds = useMemo(
    () => [
      ...offers.map(offer => offer.id),
      ...(suggestions || []).map(suggestion => suggestion.id),
      ...additionalSuggestions.flat().map(suggestion => suggestion.id),
    ],
    [offers, suggestions, additionalSuggestions],
  );

  /**
   * Функция для получения id предложений для следующей страницы
   */
  const getNextStepSuggestions = useCallback(async () => {
    try {
      const response = await getSuggestionIds(ctx.httpApi, ctx.logger, {
        jsonQuery,
        queryString,
        offerIds: allVisibleOffersIds,
        pageNumber: additionalSuggestions.length + 1,
      });

      const nextStepSuggestions = response.map(searchResult => searchResult.itemId);

      setNextStepSuggestions(nextStepSuggestions);
    } catch (_error) {
      const dataError = new Error('Failed get-infinite-search-result-desktop');
      ctx.logger.error(dataError);
    }
  }, [additionalSuggestions.length, allVisibleOffersIds, ctx.logger, ctx.httpApi, jsonQuery, queryString]);

  /**
   * Функция для загрузки офферов по id, а так же новых id предложений
   */
  const handleLoadMoreClick = useCallback(
    async (event: SyntheticEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      const nextAllVisibleOffersIds = [...allVisibleOffersIds, ...nextStepSuggestions];

      setIsLoading(true);

      const nextStepDataPromise = await getSuggestionIds(ctx.httpApi, ctx.logger, {
        jsonQuery,
        queryString,
        offerIds: nextAllVisibleOffersIds.length > 100 ? nextAllVisibleOffersIds.slice(-100) : nextAllVisibleOffersIds,
        pageNumber: additionalSuggestions.length + 1,
      });

      const nextStepOffersPromise = getOffers(ctx.httpApi, nextStepSuggestions, jsonQuery);

      try {
        const [nextStepData, nextStepOffers] = await Promise.all([nextStepDataPromise, nextStepOffersPromise]);
        const { offersSerialized: offers, qsToUris } = nextStepOffers;
        const newNextStepSuggestionsIds = nextStepData.map(nextStepData => nextStepData.itemId);

        const newModelVersion = nextStepData && nextStepData.length > 0 ? nextStepData[0].modelVersion : null;

        if (newModelVersion) {
          setModelVersion(newModelVersion);
        }

        dispatch(setQsToUris(qsToUris));

        const newSuggestions = offers.map(offer => ({
          ...offer,
          modelVersion: newModelVersion || modelVersion,
        }));

        setAdditionalSuggestions(prevState => [...prevState, newSuggestions]);

        handleTrackSuggestions(
          suggestions,
          additionalSuggestions.length + 1,
          [...(suggestions || []), ...additionalSuggestions.flat(), ...newSuggestions],
          newSuggestions,
        );

        setIsLoading(false);
        setNextStepSuggestions(newNextStepSuggestionsIds);

        const coordinatesOffers = additionalSuggestions
          .flat()
          .map(suggestion => {
            const { geo } = suggestion;

            if (!geo) {
              return null;
            }

            return {
              offerId: suggestion.id,
              coordinates: {
                lat: geo.coordinates.lat,
                lng: geo.coordinates.lng,
              },
            };
          })
          .filter(Boolean);

        if (coordinatesOffers.length > 0) {
          dispatch(requestSuggestionsDistances(coordinatesOffers as ICoordinatesOffers[]));
        }
      } catch (error) {
        const dataError = new Error(
          `Failed to get additional suggestions info,
      suggestions ids: ${nextStepSuggestions}
      next suggestions ids: ${nextAllVisibleOffersIds}`,
        );
        ctx.logger.error(dataError);

        setIsLoading(false);
      }
    },
    [
      additionalSuggestions,
      allVisibleOffersIds,
      ctx.httpApi,
      ctx.logger,
      dispatch,
      handleTrackSuggestions,
      jsonQuery,
      modelVersion,
      nextStepSuggestions,
      queryString,
      suggestions,
    ],
  );

  const updateSuggestionDistancesSeoText = useCallback(async () => {
    let suggestionDistancesSeoText = '';

    try {
      const geoTagsData = await fetchGeoTagsSafe(ctx, { jsonQuery, subdomain });
      const isPersonToPersonURL = window.location.pathname !== '/cat.php';
      if (isPersonToPersonURL) {
        suggestionDistancesSeoText = getSuggestionDistancesSeoText(geoTagsData, directions && directions.data);
      }
      dispatch(setSuggestionDistancesSeoText(suggestionDistancesSeoText));
    } catch (_error) {
      dispatch(setSuggestionDistancesSeoText(''));
    }
  }, [ctx, jsonQuery, subdomain, dispatch, directions]);

  useEffect(() => {
    if (!equals(suggestions, suggestionsRef.current)) {
      if (mountedRef.current) {
        /**
         * Если компонент уже был отрисован, то обнуляем дополнительные предложения и версию модели
         */
        setAdditionalSuggestions([]);
        setNextStepSuggestions([]);
        setModelVersion('0');
      }

      /**
       * При первом показе и при изменении поиска отправляем аналитику по добивкам пришедшим с бекенда внутри search-offers-desktop с page_num=0
       */
      handleTrackSuggestions(suggestions, 0);
      /**
       * Получение id предложений для следующей страницы
       */
      getNextStepSuggestions();

      // Получение текста для SEO и расстояние до текущего гео только
      if (suggestions?.length && isSeoUrl) {
        updateSuggestionDistancesSeoText();
        dispatch(requestSuggestionsDistances());
      }

      suggestionsRef.current = suggestions;
    }

    mountedRef.current = true;
  }, [
    dispatch,
    getNextStepSuggestions,
    handleTrackSuggestions,
    isSeoUrl,
    suggestions,
    updateSuggestionDistancesSeoText,
  ]);

  if (!suggestions?.length && !additionalSuggestions.length) {
    return null;
  }

  return (
    <SuggestionsLayout
      content={visibleSuggestionsNodes}
      hasMoreOffers={nextStepSuggestions.length > 0}
      isLoading={isLoading}
      title={offers.length > 0 ? 'Дополнительные предложения по вашему запросу' : 'Похожие предложения'}
      onShowMoreClick={handleLoadMoreClick}
    />
  );
}
