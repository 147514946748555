// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  ICianApiSiteV1GetNewobjectsBuilderRequest,
  TCianApiSiteV1GetNewobjectsBuilderModel,
  IMappers,
  TCianApiSiteV1GetNewobjectsBuilderResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetSimilarAndBuilderNewObjectsResponseSchema84 } from '../../../../entities/schemas/GetSimilarAndBuilderNewObjectsResponseSchema84';
import type { IErrorResponseSchema85 } from '../../../../entities/schemas/ErrorResponseSchema85';

export function createCianApiSiteV1GetNewobjectsBuilderModel(
  parameters: ICianApiSiteV1GetNewobjectsBuilderRequest,
): TCianApiSiteV1GetNewobjectsBuilderModel {
  return {
    v: 1,
    apiType: 'legacy',
    method: 'GET',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-newobjects-builder/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      parameters,
    },
  };
}

export interface ICianApiSiteV1GetNewobjectsBuilderOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1GetNewobjectsBuilderRequest;
}

export async function fetchCianApiSiteV1GetNewobjectsBuilder<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetNewobjectsBuilderOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TCianApiSiteV1GetNewobjectsBuilderResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createCianApiSiteV1GetNewobjectsBuilderModel(parameters),
    config,
  )) as FetchResponse<TCianApiSiteV1GetNewobjectsBuilderResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSimilarAndBuilderNewObjectsResponseSchema84, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema85, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TCianApiSiteV1GetNewobjectsBuilderResponse>;
}
