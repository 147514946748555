import { type IHttpApi } from '@cian/http-api/shared';
import { type ILogger } from '@cian/logger';

import {
  fetchDelayedMarkSavedSearchAsReadDesktop,
  type TDelayedMarkSavedSearchAsReadDesktopRequest,
  type TDelayedMarkSavedSearchAsReadDesktopResponse,
} from 'shared/repositories/saved-searches/v1/delayed-mark-saved-search-as-read-desktop';

export function readAllOffersForSavedSearches(
  httpApi: IHttpApi,
  parameters: TDelayedMarkSavedSearchAsReadDesktopRequest,
  logger: ILogger,
) {
  fetchDelayedMarkSavedSearchAsReadDesktop({
    httpApi,
    parameters,
  }).then((response: TDelayedMarkSavedSearchAsReadDesktopResponse) => {
    if (response.statusCode !== 200) {
      const error = new Error('Failed send /v1/delayed-mark-saved-search-as-read-desktop/');

      logger.error(error);
    }
  });
}
