import { type IHttpApi } from '@cian/http-api/shared';

import {
  createModerationComplaintsModel,
  TAddFeedbackModelRequestSchema,
  TAddFeedbackModelResponseSchema,
} from '../../packages/api-models/complaints/feedback/v1/add-feedback-request';

export function sendFeedbackFrom(
  httpApi: IHttpApi,
  parameters: TAddFeedbackModelRequestSchema,
): Promise<TAddFeedbackModelResponseSchema> {
  return httpApi.fetch(createModerationComplaintsModel({ parameters }), {
    requestConfig: {
      headers: [['Content-Type', 'application/json']],
      withCredentials: false,
    },
  }) as unknown as Promise<TAddFeedbackModelResponseSchema>;
}
