// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IChatUser {
  /** Роль пользователя в чате, в котором он передан **/
  chatRole: EChatRole;
  /**
   *
   *         Пользователь имеет опубликованный профиль в каталоге специалистов.
   *         Флаг нужен для понимания того, возможен ли переход к просмотру профиля.
   *         Поле можно будет удалить после переезда на профиль собеседника в чате.
   *         Переход в профиль из каталога будет осуществляться оттуда.
   *
   */
  hasPublicProfile: boolean;
  /** Доступен ли пользователь для звонка. Раньше поле находилось в data.agent структуре **/
  isAvailableForPhoneCall: boolean;
  /** Идентификатор пользователя (realty_user_id) **/
  userId: number;
  /** Название компании. На Android НЕ используется. **/
  agencyName?: TChatUser_AgencyName;
  /** Тип пользователя. На Android НЕ используется. **/
  agentAccountType?: TChatUser_AgentAccountType;
  /**
   *
   *         Ссылка на аватарку. Используется в списке чатов на месте фото в случае,
   *         если чат инициирован с агентом, а не по объявлению.
   *
   */
  agentAvatarUrl?: TChatUser_AgentAvatarUrl;
  /**
   *
   *         Имя пользователя в соответствии с требованиями бизнеса.
   *         Если отсутствует, то клиенты отображают Идентификатор пользователя по своей логике.
   *
   */
  name?: TChatUser_Name;
  /** Ссылка на изображение, которая должна использоваться вместо имени пользователя на клиентах **/
  nameReplacementImageUrl?: TChatUser_NameReplacementImageUrl;
  /** Телефоны для контакта с пользователем, в формате 79133451133 **/
  phones?: TChatUser_Phones;
  /** Абсолютный URL на публичный профиль агента **/
  urlToAgentPublicProfile?: TChatUser_UrlToAgentPublicProfile;
}

export enum EChatRole {
  /** Другие собеседники (участники) чата, помимо инициатора (initiator) и оппонента (opponent) **/
  'Companion' = 'companion',
  /** Инициатор чата, то есть этот пользователь первым написал сообщение **/
  'Initiator' = 'initiator',
  /** Собеседник, который является мастером для оппонента, который является его суб-агентом **/
  'Master' = 'master',
  /** Тот кому написал инициатор, то есть этот пользователь получил первое сообщение от инициатора **/
  'Opponent' = 'opponent',
}
export type TChatUser_AgencyName = null | string;

export type TChatUser_AgentAccountType = null | EAgentAccountType;

export enum EAgentAccountType {
  /** Специалист **/
  'Agency' = 'agency',
  /** Застройщик **/
  'Developer' = 'developer',
  /** Собственник **/
  'Homeowner' = 'homeowner',
  /** Управляющая компания **/
  'Management_company' = 'management_company',
  /** Отдел аренды **/
  'Rent_department' = 'rent_department',
  /** Поискун **/
  'Searcher' = 'searcher',
  /** Риэлтор **/
  'Specialist' = 'specialist',
  /** Системные сообщения от администрации ЦИАН-а **/
  'System' = 'system',
}
export type TChatUser_AgentAvatarUrl = null | string;

export type TChatUser_Name = null | string;

export type TChatUser_NameReplacementImageUrl = null | string;

export type TChatUser_Phones = null | string[];

export type TChatUser_UrlToAgentPublicProfile = null | string;
