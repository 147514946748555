// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetInfiniteSearchResultDesktopRequest,
  TGetInfiniteSearchResultDesktopModel,
  IMappers,
  TGetInfiniteSearchResultDesktopResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IInfiniteSearchResultsResponseSchema } from '../../entities/schemas/InfiniteSearchResultsResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetInfiniteSearchResultDesktopModel(
  parameters: TGetInfiniteSearchResultDesktopRequest,
): TGetInfiniteSearchResultDesktopModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers',
    pathApi: '/v1/get-infinite-search-result-desktop/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IGetInfiniteSearchResultDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetInfiniteSearchResultDesktopRequest;
}

export async function fetchGetInfiniteSearchResultDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetInfiniteSearchResultDesktopOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetInfiniteSearchResultDesktopResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetInfiniteSearchResultDesktopModel(parameters),
    config,
  )) as FetchResponse<TGetInfiniteSearchResultDesktopResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IInfiniteSearchResultsResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetInfiniteSearchResultDesktopResponse>;
}
