// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TDelayedMarkSavedSearchAsReadDesktopRequest,
  TDelayedMarkSavedSearchAsReadDesktopModel,
  IMappers,
  TDelayedMarkSavedSearchAsReadDesktopResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IDelayedMarkSavedSearchAsReadDesktopResponseSchema } from '../../entities/saved_search/DelayedMarkSavedSearchAsReadDesktopResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createDelayedMarkSavedSearchAsReadDesktopModel(
  parameters: TDelayedMarkSavedSearchAsReadDesktopRequest,
): TDelayedMarkSavedSearchAsReadDesktopModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'saved-searches',
    pathApi: '/v1/delayed-mark-saved-search-as-read-desktop/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IDelayedMarkSavedSearchAsReadDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TDelayedMarkSavedSearchAsReadDesktopRequest;
}

export async function fetchDelayedMarkSavedSearchAsReadDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IDelayedMarkSavedSearchAsReadDesktopOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TDelayedMarkSavedSearchAsReadDesktopResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createDelayedMarkSavedSearchAsReadDesktopModel(parameters),
    config,
  )) as FetchResponse<TDelayedMarkSavedSearchAsReadDesktopResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IDelayedMarkSavedSearchAsReadDesktopResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TDelayedMarkSavedSearchAsReadDesktopResponse>;
}
