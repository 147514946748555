// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TSearchOffersDesktopRequest,
  TSearchOffersDesktopModel,
  IMappers,
  TSearchOffersDesktopResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { ISearchOffersDesktopResponseV2Schema } from '../../entities/entities/SearchOffersDesktopResponseV2Schema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createSearchOffersDesktopModel(parameters: TSearchOffersDesktopRequest): TSearchOffersDesktopModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers',
    pathApi: '/v2/search-offers-desktop/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface ISearchOffersDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TSearchOffersDesktopRequest;
}

export async function fetchSearchOffersDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ISearchOffersDesktopOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TSearchOffersDesktopResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createSearchOffersDesktopModel(parameters),
    config,
  )) as FetchResponse<TSearchOffersDesktopResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISearchOffersDesktopResponseV2Schema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TSearchOffersDesktopResponse>;
}
