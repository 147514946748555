import {
  fetchGetInfiniteSearchResultDesktop,
  TGetInfiniteSearchResultDesktopResponse,
} from 'shared/repositories/search-offers/v1/get-infinite-search-result-desktop';
import {
  fetchGetOffersByIdsDesktop,
  type TGetOffersByIdsDesktopResponse,
} from 'shared/repositories/search-offers/v1/get-offers-by-ids-desktop';

import type { IJsonQuery } from '../json_query';
import type { IOffer } from '../offer';
import type { IHttpApi } from '@cian/http-api/shared';
import type { ILogger } from '@cian/logger';
import type { IInfiniteSearchResultSchema } from 'shared/repositories/search-offers/entities/infinite_search_result/InfiniteSearchResultSchema';
import type { IInfiniteSearchResultsResponseSchema } from 'shared/repositories/search-offers/entities/schemas/InfiniteSearchResultsResponseSchema';
import type { IMgetOffersByIdsResponseSchema } from 'shared/repositories/search-offers/entities/schemas/MgetOffersByIdsResponseSchema';

export interface IQsToUris {
  [key: string]: string;
}

interface IGetOffersResponse {
  offersSerialized: IOffer[];
  qsToUris: IQsToUris;
}

export interface IGetSuggestionIds {
  jsonQuery: IJsonQuery;
  offerIds: number[];
  pageNumber: number;
  queryString: string;
}

export interface IInfiniteSearchResult {
  itemId: number;
  modelVersion: string;
  score: number;
}

export interface IOffersMLResponse {
  offersSerialized: IOffer[];
  qsToUris: IQsToUris;
}

export const getOffers = async (
  httpApi: IHttpApi,
  cianOfferIds: number[],
  jsonQuery: IJsonQuery,
): Promise<IGetOffersResponse> => {
  const { response }: TGetOffersByIdsDesktopResponse = await fetchGetOffersByIdsDesktop({
    httpApi,
    parameters: {
      cianOfferIds,
      jsonQuery,
    },
  });

  const { offersSerialized, qsToUris } = response as IMgetOffersByIdsResponseSchema;

  return {
    offersSerialized: offersSerialized as IOffer[],
    qsToUris: qsToUris as IQsToUris,
  };
};

export const getSuggestionIds = async (
  httpApi: IHttpApi,
  logger: ILogger,
  parameters: IGetSuggestionIds,
): Promise<IInfiniteSearchResultSchema[]> => {
  try {
    const { response }: TGetInfiniteSearchResultDesktopResponse = await fetchGetInfiniteSearchResultDesktop({
      httpApi,
      parameters,
    });

    return (response as IInfiniteSearchResultsResponseSchema).infiniteSearchResult;
  } catch (_error) {
    const dataError = new Error('Failed get-infinite-search-result-desktop');
    logger.error(dataError);

    return [];
  }
};
