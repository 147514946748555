import { type IHttpApi } from '@cian/http-api';
import { type ILogger } from '@cian/logger';

import {
  fetchHideOfferWebsite,
  THideOfferWebsiteResponse,
  type THideOfferWebsiteRequest,
} from 'shared/repositories/hidden-objects/v1/hide-offer-website';

interface IDependencies {
  httpApi: IHttpApi;
  logger: ILogger;
}

export async function postHideOffer(
  dependencies: IDependencies,
  parameters: THideOfferWebsiteRequest,
): Promise<THideOfferWebsiteResponse> {
  const { httpApi, logger } = dependencies;

  try {
    const response: THideOfferWebsiteResponse = await fetchHideOfferWebsite({
      httpApi,
      parameters,
    });

    return response;
  } catch (ex) {
    logger.error(ex, { domain: 'shared.api.hidden_objects.postHideOffer' });

    throw ex;
  }
}
