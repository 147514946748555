import { type IHttpApi } from '@cian/http-api/shared';

import { assertUnknownError } from './assert';
import {
  fetchAddOfferToComparison,
  TAddOfferToComparisonRequest,
  TAddOfferToComparisonResponse,
} from '../../../repositories/offers-comparison/v2/add-offer-to-comparison';
import { IAddOfferToComparison } from '../../../types/offersComparison';

export async function addOfferToComparisonRequest({
  httpApi,
  parameters,
}: {
  httpApi: IHttpApi;
  parameters: TAddOfferToComparisonRequest;
}): Promise<IAddOfferToComparison> {
  const response: TAddOfferToComparisonResponse = await fetchAddOfferToComparison({
    httpApi,
    parameters,
    config: {},
  });

  assertUnknownError(response);

  return response.response as IAddOfferToComparison;
}
