// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IUserLogOnDataSchema } from './UserLogOnDataSchema';

export interface ICreateSavedSearchWebPublicResponseSchema {
  /** Информация для авторегистрации анонимного пользователя **/
  logonInfo?: IUserLogOnDataSchema;
  /** Статус создания СП и подписки **/
  status: EStatus;
}

export enum EStatus {
  /** Для активации email-подписки требуется подтверждение. Отправлено письмо с активацией **/
  'EmailConfirmationRequired' = 'emailConfirmationRequired',
  /** Ошибка создания СП. Возможно что-то внутри сдеградировало **/
  'Error' = 'error',
  /** Только для авторизованных пользователей **/
  'NotAuthUser' = 'notAuthUser',
  /** СП с указанным фильтром поиска уже существует у пользователя **/
  'SavedSearchAlreadyExist' = 'savedSearchAlreadyExist',
  /** СП создан **/
  'Success' = 'success',
  /** Пользователь с указанным email уже зарегистрирован **/
  'UserAlreadyRegistered' = 'userAlreadyRegistered',
}
