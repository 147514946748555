import { type IHttpApi } from '@cian/http-api/shared';

import { assertUnknownError } from './assert';
import {
  fetchDeleteOfferFromComparison,
  TDeleteOfferFromComparisonRequest,
  TDeleteOfferFromComparisonResponse,
} from '../../../repositories/offers-comparison/v1/delete-offer-from-comparison';
import { IDeleteFromComparison } from '../../../types/offersComparison';

export async function deleteOfferFromComparisonRequest({
  httpApi,
  parameters,
}: {
  httpApi: IHttpApi;
  parameters: TDeleteOfferFromComparisonRequest;
}): Promise<IDeleteFromComparison> {
  const response: TDeleteOfferFromComparisonResponse = await fetchDeleteOfferFromComparison({
    httpApi,
    parameters,
    config: {},
  });

  assertUnknownError(response);

  return response.response as IDeleteFromComparison;
}
