// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  ICianApiSiteV1GetDeveloperInfoRequest,
  TCianApiSiteV1GetDeveloperInfoModel,
  IMappers,
  TCianApiSiteV1GetDeveloperInfoResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetDeveloperInfoResponseSchema74 } from '../../../../entities/schemas/GetDeveloperInfoResponseSchema74';
import type { IErrorResponseSchema75 } from '../../../../entities/schemas/ErrorResponseSchema75';
import type { IErrorResponseSchema76 } from '../../../../entities/schemas/ErrorResponseSchema76';

export function createCianApiSiteV1GetDeveloperInfoModel(
  parameters: ICianApiSiteV1GetDeveloperInfoRequest,
): TCianApiSiteV1GetDeveloperInfoModel {
  return {
    v: 1,
    apiType: 'legacy',
    method: 'GET',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-developer-info/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
      404: {
        contentType: 'json',
      },
    },
    request: {
      parameters,
    },
  };
}

export interface ICianApiSiteV1GetDeveloperInfoOptions<TResponse200, TResponse400, TResponse404> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400, TResponse404>;
  parameters: ICianApiSiteV1GetDeveloperInfoRequest;
}

export async function fetchCianApiSiteV1GetDeveloperInfo<TResponse200, TResponse400, TResponse404>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetDeveloperInfoOptions<TResponse200, TResponse400, TResponse404>): Promise<
  FetchResponse<TCianApiSiteV1GetDeveloperInfoResponse> | TResponse200 | TResponse400 | TResponse404
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createCianApiSiteV1GetDeveloperInfoModel(parameters),
    config,
  )) as FetchResponse<TCianApiSiteV1GetDeveloperInfoResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetDeveloperInfoResponseSchema74, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema75, headers);
    }
    if (statusCode === 404) {
      return mappers[404](response as IErrorResponseSchema76, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TCianApiSiteV1GetDeveloperInfoResponse>;
}
