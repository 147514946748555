// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  ICianApiSiteV1GetSimilarNewobjectsRequest,
  TCianApiSiteV1GetSimilarNewobjectsModel,
  IMappers,
  TCianApiSiteV1GetSimilarNewobjectsResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetSimilarAndBuilderNewObjectsResponseSchema80 } from '../../../../entities/schemas/GetSimilarAndBuilderNewObjectsResponseSchema80';
import type { IErrorResponseSchema81 } from '../../../../entities/schemas/ErrorResponseSchema81';

export function createCianApiSiteV1GetSimilarNewobjectsModel(
  parameters: ICianApiSiteV1GetSimilarNewobjectsRequest,
): TCianApiSiteV1GetSimilarNewobjectsModel {
  return {
    v: 1,
    apiType: 'legacy',
    method: 'GET',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-similar-newobjects/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      parameters,
    },
  };
}

export interface ICianApiSiteV1GetSimilarNewobjectsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1GetSimilarNewobjectsRequest;
}

export async function fetchCianApiSiteV1GetSimilarNewobjects<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetSimilarNewobjectsOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TCianApiSiteV1GetSimilarNewobjectsResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createCianApiSiteV1GetSimilarNewobjectsModel(parameters),
    config,
  )) as FetchResponse<TCianApiSiteV1GetSimilarNewobjectsResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSimilarAndBuilderNewObjectsResponseSchema80, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema81, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TCianApiSiteV1GetSimilarNewobjectsResponse>;
}
