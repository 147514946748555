// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TCianApiSiteV1GetUserDataByRequestModel,
  IMappers,
  TCianApiSiteV1GetUserDataByRequestResponse,
  ICianApiSiteV1GetUserDataByRequestResponseData400,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IFastFiltersUserResponseSchema82 } from '../../../../entities/schemas/FastFiltersUserResponseSchema82';

export function createCianApiSiteV1GetUserDataByRequestModel(): TCianApiSiteV1GetUserDataByRequestModel {
  return {
    v: 1,
    apiType: 'legacy',
    method: 'GET',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-user-data-by-request/',
    response: {
      '200': {
        contentType: 'json',
      },
      '400': {
        contentType: 'json',
      },
    },
  };
}

export interface ICianApiSiteV1GetUserDataByRequestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchCianApiSiteV1GetUserDataByRequest<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: ICianApiSiteV1GetUserDataByRequestOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TCianApiSiteV1GetUserDataByRequestResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createCianApiSiteV1GetUserDataByRequestModel(),
    config,
  )) as FetchResponse<TCianApiSiteV1GetUserDataByRequestResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IFastFiltersUserResponseSchema82, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICianApiSiteV1GetUserDataByRequestResponseData400, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TCianApiSiteV1GetUserDataByRequestResponse>;
}
