import { ca } from '@cian/analytics';

import { TJsonQuery } from '../../../../packages/JsonQuery';
import { ITrackingPageData } from '../../../serp/state/tracking';
import { EFrequency } from 'shared/repositories/saved-searches/entities/create_saved_search/CreateSavedSearchWebPublicRequestSchema';

interface ITrackSubscribedParameters {
  frequency: EFrequency;
  subscribeToNews: boolean;
  title?: string;
  email?: string;
  defaultTitle?: string | null;
  defaultEmail?: string | null;
  jsonQuery: TJsonQuery;
  page: ITrackingPageData;
}

enum EFilledEmail {
  /** Равен стандартному емейлу */
  Default = 0,
  /** Введен пользователем */
  Custom = 2,
}

const notificationFrequencyMap: { [key in EFrequency]: string } = {
  [EFrequency.Asap]: 'Asap',
  [EFrequency.Hourly]: 'Hourly',
  [EFrequency.Daily]: 'Daily',
  [EFrequency.Weekly]: 'Weekly',
};

export const trackSuburbanSaveSearchSubscribed = ({
  frequency,
  subscribeToNews,
  title,
  email,
  defaultTitle,
  defaultEmail,
  jsonQuery,
  page,
}: ITrackSubscribedParameters) => {
  const filledTitle = title === defaultTitle ? `auto_${defaultTitle}` : title;

  const filledEmail = email === defaultEmail ? EFilledEmail.Default : EFilledEmail.Custom;

  ca('eventSite', {
    name: 'oldevent',
    category: 'saved_search',
    action: 'show',
    label: 'success',
    sc: jsonQuery,
    page: {
      ...page,
      extra: {
        not_freq: notificationFrequencyMap[frequency],
        not_yes: subscribeToNews ? 1 : 0,
        filled: {
          title: filledTitle,
          email: filledEmail,
        },
      },
    },
    useLastProducts: true,
  });
};
