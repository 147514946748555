// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetOffersByIdsDesktopRequest,
  TGetOffersByIdsDesktopModel,
  IMappers,
  TGetOffersByIdsDesktopResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IMgetOffersByIdsResponseSchema } from '../../entities/schemas/MgetOffersByIdsResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetOffersByIdsDesktopModel(
  parameters: TGetOffersByIdsDesktopRequest,
): TGetOffersByIdsDesktopModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers',
    pathApi: '/v1/get-offers-by-ids-desktop/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IGetOffersByIdsDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetOffersByIdsDesktopRequest;
}

export async function fetchGetOffersByIdsDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetOffersByIdsDesktopOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetOffersByIdsDesktopResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetOffersByIdsDesktopModel(parameters),
    config,
  )) as FetchResponse<TGetOffersByIdsDesktopResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IMgetOffersByIdsResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetOffersByIdsDesktopResponse>;
}
