// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  IGetProjectsSuggestRequest,
  TGetProjectsSuggestModel,
  IMappers,
  TGetProjectsSuggestResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetProjectsSuggestResponse } from '../../entities/v1_entities/GetProjectsSuggestResponse';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetProjectsSuggestModel(parameters: IGetProjectsSuggestRequest): TGetProjectsSuggestModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'GET',
    microserviceName: 'countryside-constructors',
    pathApi: '/v1/get-projects-suggest/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      parameters,
    },
  };
}

export interface IGetProjectsSuggestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetProjectsSuggestRequest;
}

export async function fetchGetProjectsSuggest<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetProjectsSuggestOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetProjectsSuggestResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetProjectsSuggestModel(parameters),
    config,
  )) as FetchResponse<TGetProjectsSuggestResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetProjectsSuggestResponse, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetProjectsSuggestResponse>;
}
