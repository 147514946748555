// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TCianApiSiteV1ConvertJsonQueryToSeoUrlRequest,
  TCianApiSiteV1ConvertJsonQueryToSeoUrlModel,
  IMappers,
  TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IConvertJsonQueryToUrlResponseSchema98 } from '../../../../entities/schemas/ConvertJsonQueryToUrlResponseSchema98';
import type { IErrorResponseSchema99 } from '../../../../entities/schemas/ErrorResponseSchema99';

export function createCianApiSiteV1ConvertJsonQueryToSeoUrlModel(
  parameters: TCianApiSiteV1ConvertJsonQueryToSeoUrlRequest,
): TCianApiSiteV1ConvertJsonQueryToSeoUrlModel {
  return {
    v: 1,
    apiType: 'legacy',
    method: 'POST',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/convert-json-query-to-seo-url',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface ICianApiSiteV1ConvertJsonQueryToSeoUrlOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TCianApiSiteV1ConvertJsonQueryToSeoUrlRequest;
}

export async function fetchCianApiSiteV1ConvertJsonQueryToSeoUrl<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1ConvertJsonQueryToSeoUrlOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createCianApiSiteV1ConvertJsonQueryToSeoUrlModel(parameters),
    config,
  )) as FetchResponse<TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IConvertJsonQueryToUrlResponseSchema98, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema99, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse>;
}
