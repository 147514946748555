// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetNewbuildingLayoutWithOffersDesktopRequest,
  TGetNewbuildingLayoutWithOffersDesktopModel,
  IMappers,
  TGetNewbuildingLayoutWithOffersDesktopResponse,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig, FetchResponse } from '@cian/http-api';
import type { IGetNewbuildingLayoutWithOffersDesktopResponseSchema } from '../../entities/entities/GetNewbuildingLayoutWithOffersDesktopResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export function createGetNewbuildingLayoutWithOffersDesktopModel(
  parameters: TGetNewbuildingLayoutWithOffersDesktopRequest,
): TGetNewbuildingLayoutWithOffersDesktopModel {
  return {
    v: 1,
    apiType: 'public',
    method: 'POST',
    microserviceName: 'search-offers',
    pathApi: '/v1/get-newbuilding-layout-with-offers-desktop/',
    response: {
      200: {
        contentType: 'json',
      },
      400: {
        contentType: 'json',
      },
    },
    request: {
      contentType: 'json',
      parameters,
    },
  };
}

export interface IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetNewbuildingLayoutWithOffersDesktopRequest;
}

export async function fetchGetNewbuildingLayoutWithOffersDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400>): Promise<
  FetchResponse<TGetNewbuildingLayoutWithOffersDesktopResponse> | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = (await httpApi.fetch(
    createGetNewbuildingLayoutWithOffersDesktopModel(parameters),
    config,
  )) as FetchResponse<TGetNewbuildingLayoutWithOffersDesktopResponse>;
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingLayoutWithOffersDesktopResponseSchema, headers);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema, headers);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as FetchResponse<TGetNewbuildingLayoutWithOffersDesktopResponse>;
}
